import { AgdirRemoteMFAEnvironment, Environment } from '@agdir/environment/domain';

export const weHaveSharedMfas = (env: Environment) => {
	env.register(
		new AgdirRemoteMFAEnvironment({
			authSite: '/auth',
			fileManager: '/file-manager',
			tasksManagement: '/journal/tasks-management',
			journal: '/journal',
			catalogue: '/journal',
		}),
	);
};
