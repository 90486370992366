import { AgdirApiEnvironment, AgdirAwsEnvironment, AgdirGoogleEnvironment, AgdirOneSignalEnvironment, Environment } from '@agdir/environment/domain';
import { weHaveSharedMfas } from './we-have-shared-mfas';
import { weHaveSharedVendors } from './we-have-shared-vendors';

export const environment = new Environment({
	env: 'prod',
	production: true,
	remotes: new Map([['farmSiteUrl', 'https://agdir.farm']]),
});
environment
	.register(
		new AgdirAwsEnvironment({
			region: 'eu-west-1',
			userPoolId: 'eu-west-1_5qaPFym1J',
			userPoolWebClientId: 'r5p266325ik1j0dmkjjddem8p',
		}),
	)
	.register(
		new AgdirApiEnvironment({
			webHost: 'http://agdir.farm',
			hostv3: 'https://v3.api.agdir.farm',
			host: 'https://api.agdir.farm',
		}),
	)
	.register(
		new AgdirGoogleEnvironment({
			apiKey: 'AIzaSyAd79l2jlY5ekNi-oX7lpbJbtNt6iP9_1c',
			reCaptchaV3SiteKey: '6LftOU0hAAAAAGvXGVjMOi-PUUxs6luj9GNiWp5G',
			googleAnalyticsTrackingCode: 'G-M3NJG4JP85',
		}),
	)
	.register(
		new AgdirOneSignalEnvironment({
			appId: '109315ca-ca3b-4ce8-83cf-12b449ec8858',
			safari_web_id: 'web.onesignal.auto.48e79a0f-c6ba-48bf-ae98-32501b011889',
			notifyButton: {
				enable: true,
			},
		}),
	);

weHaveSharedMfas(environment);
weHaveSharedVendors(environment);
